var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('titulo-seccion',[_c('template',{slot:"area-rutas"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Inicio")]),_vm._v(" / "),_c('span',{staticClass:"active"},[_vm._v("Buscador")])],1),_c('template',{slot:"area-titulo"},[_c('h3',{staticClass:"text-center"},[_vm._v("Buscador")])])],2),_c('div',{staticClass:"container mt-4",attrs:{"id":"content-page"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"mt-3 input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.buscar),expression:"buscar"}],staticClass:"form-control",attrs:{"type":"search","placeholder":"Buscar.."},domProps:{"value":(_vm.buscar)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscarResultado()},"input":function($event){if($event.target.composing)return;_vm.buscar=$event.target.value}}}),_c('button',{staticClass:"btn btn-fiscalia",style:({
              '--background-color-btn': _vm.config.disenio.datos.color_secundario,
            }),attrs:{"type":"button"},on:{"click":function($event){return _vm.buscarResultado()}}},[_vm._v(" Buscar ")])])]),_c('div',{staticClass:"gap-2 col-md-4 d-grid d-md-flex justify-content-md-end"})]),_c('div',{staticClass:"my-4 row"},[_c('div',{staticClass:"p-0 col-12 media-list media-bordered"},[_vm._l((_vm.items),function(item,index){return _c('b-media',{key:index,attrs:{"no-body":""}},[_c('b-media-body',{staticClass:"ms-1"},[_c('h5',{staticClass:"mt-0"},[_c('router-link',{attrs:{"to":_vm.slugEntradaUrl(item)}},[_c('text-highlight',{attrs:{"highlightStyle":"background:#FFFFCC","queries":_vm.highlighter}},[_vm._v(_vm._s(item.titulo))])],1)],1),_c('b-card-text',{staticClass:"my-2 text-secondary text-uppercase"},[_c('strong',[_vm._v("("+_vm._s(item.tipo_entrada.nombre)+")")])]),_c('div',{staticClass:"mb-4 item-descripcion-search"},[_c('text-highlight',{attrs:{"highlightStyle":"background:#FFFFCC;","queries":_vm.highlighter}},[_vm._v(_vm._s(item.context))])],1),_c('b-card-text',{staticClass:"small text-secondary text-uppercase"},[_c('strong',[_vm._v("Creado el "+_vm._s(item.publicado))])])],1)],1)}),(_vm.total == 0)?_c('b-card-text',[_c('strong',[_vm._v("No pudimos encontrar ningún contenido que coincida: "+_vm._s(_vm.search))])]):(!_vm.items.length)?_c('b-card-text',[_c('strong',[_vm._v("¿Que estás buscando?")])]):_vm._e()],2)]),_c('div',{staticClass:"mt-5 mb-5 text-center row",attrs:{"id":"navegacion"}},[(_vm.items.length)?_c('b-pagination-nav',{staticClass:"my-0",style:({
          '--background-color-secondary':
            _vm.config.disenio.datos.color_secundario,
          '--background-color-tertiary': _vm.config.disenio.datos.color_terciario,
        }),attrs:{"use-router":"","link-gen":_vm.linkGen,"number-of-pages":_vm.totalRows,"per-page":_vm.perPage,"first-number":"","last-number":"","align":"center"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }