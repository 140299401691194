<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> /
        <span class="active">Buscador</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Buscador</h3>
      </template>
    </titulo-seccion>
    <div id="content-page" class="container mt-4">
      <div class="row">
        <div class="col-md-8">
          <div class="mt-3 input-group">
            <input
              class="form-control"
              v-model="buscar"
              type="search"
              @keyup.enter="buscarResultado()"
              placeholder="Buscar.."
            />
            <button
              class="btn btn-fiscalia"
              :style="{
                '--background-color-btn': config.disenio.datos.color_secundario,
              }"
              type="button"
              @click="buscarResultado()"
            >
              Buscar
            </button>
          </div>
        </div>
        <div
          class="gap-2 col-md-4 d-grid d-md-flex justify-content-md-end"
        ></div>
      </div>
      <div class="my-4 row">
        <div class="p-0 col-12 media-list media-bordered">
          <b-media no-body v-for="(item, index) in items" :key="index">
            <!-- <b-media-aside>
                            <div class="rounded box-imagen-buscador box-imagen1-buscador">
                                <b-img
                                    v-if="item.imagen"
                                    :src="item.imagen" 
                                    blank-color="#ccc"
                                    center
                                    fluid
                                    class="rounded"
                                    :alt="item.titulo"
                                />
                            </div>
                        </b-media-aside> -->
            <b-media-body class="ms-1">
              <h5 class="mt-0">
                <router-link :to="slugEntradaUrl(item)">
                  <text-highlight
                    highlightStyle="background:#FFFFCC"
                    :queries="highlighter"
                    >{{ item.titulo }}</text-highlight
                  >
                </router-link>
              </h5>
              <b-card-text class="my-2 text-secondary text-uppercase">
                <strong>({{ item.tipo_entrada.nombre }})</strong>
              </b-card-text>
              <div class="mb-4 item-descripcion-search">
                <text-highlight
                  highlightStyle="background:#FFFFCC;"
                  :queries="highlighter"
                  >{{ item.context }}</text-highlight
                >
              </div>

              <!-- <b-card-text class="my-2 text-secondary">
                                <router-link v-if="item.tipo_entrada_id!=4" class="btn btn-sm btn-fiscalia" :to="tipoEntradaUrl(item.tipo_entrada)">
                                    <strong>{{item.tipo_entrada.nombre}}</strong>
                                </router-link>
                            </b-card-text> -->

              <b-card-text class="small text-secondary text-uppercase">
                <strong>Creado el {{ item.publicado }}</strong>
              </b-card-text>
            </b-media-body>
          </b-media>
          <b-card-text v-if="total == 0">
            <strong
              >No pudimos encontrar ningún contenido que coincida:
              {{ search }}</strong
            >
          </b-card-text>
          <b-card-text v-else-if="!items.length">
            <strong>¿Que estás buscando?</strong>
          </b-card-text>
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 text-center row">
        <b-pagination-nav
          v-if="items.length"
          use-router
          v-model="currentPage"
          :link-gen="linkGen"
          :number-of-pages="totalRows"
          :per-page="perPage"
          first-number
          last-number
          align="center"
          class="my-0"
          :style="{
            '--background-color-secondary':
              config.disenio.datos.color_secundario,
            '--background-color-tertiary': config.disenio.datos.color_terciario,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { BPaginationNav, BMedia, BMediaBody } from "bootstrap-vue";
import TituloSeccion from "@/views/secciones/TituloSeccion.vue";

// import WordHighlighter from "vue-word-highlighter";
import TextHighlight from "vue-text-highlight";

export default {
  components: {
    TituloSeccion,
    BPaginationNav,
    BMedia,
    // BImg,
    // BMediaAside,
    BMediaBody,
    //BBadge,
    // BInputGroup,
    // BInputGroupAppend,
    // BButton,
    // BFormInput,
    TextHighlight,
    // WordHighlighter
  },
  metaInfo: {
    title: "Ministerio Público",
    titleTemplate: "%s | Buscador",
    htmlAttrs: {
      lang: "es-ES",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "¿Que estás buscando?" },
    ],
  },
  directives: {},
  data() {
    return {
      titulo: "Buscador",
      items: [],
      buscar: "",
      perPage: 20,
      totalRows: 1,
      currentPage: 1,
      search: "",
      total: 1,
      highlighter: "",
    };
  },
  created() {
    if (Object.keys(this.$route.query).length === 0) {
      this.currentPage = 1;
    } else {
      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page;
        this.buscar = this.$route.query.texto;
        this.search = this.buscar;
        this.listarResultados();
      } else {
        this.currentPage = 1;
        this.buscar = this.$route.query.texto;
        this.search = this.buscar;
        this.listarResultados();
      }
    }
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
  },
  watch: {
    buscar: function (val) {
      if (val == "" || val.length == 0) {
        this.items = [];
        this.currentPage = 1;
        this.totalRows = 1;
        this.total = 1;
        this.search = "";
        if (this.$route.fullPath != "/buscador") {
          this.$router.push({ name: "buscador" });
        }
      }
    },
    $route: function () {
      if (Object.keys(this.$route.query).length === 0) {
        this.items = [];
        this.currentPage = 1;
        this.totalRows = 1;
        this.total = 1;
        this.buscar = "";
        this.search = "";
      } else {
        if (this.$route.query.page) {
          this.currentPage = this.$route.query.page;
          this.buscar = this.$route.query.texto;
          this.search = this.buscar;
          this.listarResultados();
        } else {
          this.currentPage = 1;
          this.buscar = this.$route.query.texto;
          this.search = this.buscar;
          this.listarResultados();
        }
      }
    },
  },

  methods: {
    slugEntradaUrl(item) {
      if (item.tipo_entrada_id == 1) {
        return "noticia/" + item.url;
      } else if (item.tipo_entrada_id == 2) {
        return "convocatoria/" + item.url;
      } else if (item.tipo_entrada_id == 3) {
        return "evento/" + item.url;
      } else if (item.tipo_entrada_id == 4) {
        return "pagina/" + item.url;
      } else if (item.tipo_entrada_id == 5) {
        return "estadistica/" + item.url;
      } else {
        return "publicaciones/" + item.tipo_entrada.url + "/" + item.url;
      }
    },
    tipoEntradaUrl(item) {
      if (item.estado) {
        return "publicaciones/" + item.url;
      } else {
        return item.url;
      }
    },
    linkGen(pageNum) {
      if (pageNum === 1) {
        return {
          path: "/buscador?texto=" + this.buscar,
        };
      } else {
        return {
          path: "/buscador?texto=" + this.buscar + "&page=" + pageNum,
        };
      }
    },
    listarResultados() {
      if (this.buscar) {
        var url =
          "/front/buscador?page=" + this.currentPage + "&buscar=" + this.buscar;
        axiosIns
          .get(url)
          .then((res) => {
            this.items = res.data.data;
            this.totalRows = res.data.last_page;
            this.total = res.data.total;
            this.buscar = this.buscar.trim();
            this.highlighter = this.buscar.split(/\s+/);
            for (let index = 0; index < this.items.length; index++) {
              this.items[index].context = this.snippet(
                this.items[index].context,
                this.highlighter
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    snippet(stringToSearch, phrase) {
      var words = phrase.join("\\S*(\\s\\S+){0,4}|(\\S+\\s){0,4}\\S*");
      var regExp = eval(
        "/(\\S+\\s){0,4}\\S*" + words + "\\S*(\\s\\S+){0,4}/gi"
      );
      let wordText = stringToSearch.match(regExp);
      return wordText.join(" (...) ");
    },
    buscarResultado() {
      if (this.currentPage == 1) {
        if (this.search == this.buscar) {
          this.listarResultados();
        } else {
          this.$router.push({
            path: "/buscador",
            query: { texto: this.buscar },
          });
          this.total = 1;
          this.search = this.buscar;
        }
      } else {
        this.$router.push({ path: "/buscador", query: { texto: this.buscar } });
      }
    },
  },
};
</script>

<style>
.cursor {
  cursor: pointer;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-aside {
  display: flex;
  margin-right: 1rem;
}
.align-self-start {
  align-self: flex-start !important;
}
.media-list .media {
  padding: 1.25rem;
  margin-top: 0;
}
.media-bordered .media:not(:first-child) {
  border-top: 1px solid #ebe9f1;
}
.card-title {
  font-size: 14px;
}
.item-name {
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-by {
  font-weight: 400;
  font-size: 0.875rem;
}
.item-descripcion {
  -webkit-box-ordinal-group: 4;
  order: 3;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
}
.box-imagen-buscador {
  height: 15rem;
}
.box-imagen-buscador img {
  width: 300px;
  height: 100%;
}
.box-imagen1-buscador img {
  object-fit: cover;
}
.embed-responsive-4by3 {
  height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
}
@media (max-width: 700px) {
  .box-imagen-buscador {
    width: 100px;
    height: 5rem;
  }
  .embed-responsive-4by3 {
    height: calc(var(--vh, 1vh) * 100 - 10rem) !important;
  }
}
</style>
